import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PackagingGraphicCollectionWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'packagingUvGraphicCollectionType';
  }

  get label() {
    return this.i18n.gettext('graphic collection');
  }

  get calculatePrice() {
    return false;
  }

  //Need if you shouldn't call price API
  // propagateClick() {
  //   if (this.clickedItem) {
  //     this.setState({isChosen: true});
  //     this.props.delegate.setConfigurationItem(this.name, this.clickedItem.id, null, true);
  //   }
  // }
}
