import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  AlbumPackagingProduct,
  AlbumPackagingPrice,
  AlbumPackagingType,
  AlbumBagColorType,
  AlbumPackagingUvGraphicType,
  AlbumPackagingUvGraphicThemeType,
  AlbumPackagingUvGraphicElementType,
  AlbumPackagingUvGraphicText,
  AlbumPackagingCoverPhotoType,
  AlbumPackagingUvGraphicColorType,
  AlbumPackagingUvGraphicInheritedType,
  AlbumPackagingGraphicCollectionType,
  AlbumPackagingUvGraphicInheritedInfos
} from './models';

export class AlbumPackagingProductCollection extends LoadableCollection {
  get name() {
    return 'albumPackagingProducts';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_PRODUCTS_URL;
  }

  get model() {
    return AlbumPackagingProduct;
  }
}

export class AlbumPackagingPriceCollection extends LoadableCollection {
  get name() {
    return 'albumPackagingPrice';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_PRICES_URL;
  }

  get model() {
    return AlbumPackagingPrice;
  }
}

export class AlbumPackagingTypeCollection extends LoadableCollection {
  get name() {
    return 'packagingTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_TYPES_URL;
  }

  get model() {
    return AlbumPackagingType;
  }

  get loadParams() {
    return ['formatType'];
  }
}

export class AlbumPackagingBagColorsTypesCollection extends LoadableCollection {
  get name() {
    return 'bagColorTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_BAG_COLORS_URL;
  }

  get model() {
    return AlbumBagColorType;
  }

  get loadParams() {
    return ['packagingType', 'formatType'];
  }
}

export class AlbumPackagingUvGraphicTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_UV_GRAPHIC_TYPE_URL;
  }

  get model() {
    return AlbumPackagingUvGraphicType;
  }

  get loadParams() {
    return ['packagingType', 'formatType', 'packagingUvGraphicInheritedType'];
  }
}

export class AlbumPackagingUvGraphicThemeCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicThemeTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_UV_GRAPHIC_THEME_URL;
  }

  get model() {
    return AlbumPackagingUvGraphicThemeType;
  }

  get loadParams() {
    return [
      'packagingType',
      'formatType',
      'packagingUvGraphicType',
      'packagingUvGraphicInheritedType'
    ];
  }
}

export class AlbumPackagingUvGraphicElementCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicElementTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_UV_GRAPHIC_ELEMENT_URL;
  }

  get model() {
    return AlbumPackagingUvGraphicElementType;
  }

  get loadParams() {
    return [
      'packagingType',
      'formatType',
      'packagingUvGraphicType',
      'packagingUvGraphicThemeType',
      'packagingUvGraphicInheritedType',
      'packagingUvGraphicCollectionType',
      'albumConfigurationId'
    ];
  }
}

export class AlbumPackagingUvGraphicTextsCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'packagingUvGraphicTextsCollection';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_UV_GRAPHIC_TEXT_URL;
  }

  get model() {
    return AlbumPackagingUvGraphicText;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'packagingUvGraphicType',
      'packagingUvGraphicElementType',
      'packagingUvGraphicInheritedType'
    ];
  }
}

export class AlbumPackagingCoverPhotoTypesCollection extends LoadableCollection {
  get name() {
    return 'coverPhotoTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_COVER_PHOTO_URL;
  }

  get model() {
    return AlbumPackagingCoverPhotoType;
  }

  get loadParams() {
    return ['packagingType', 'formatType'];
  }
}

export class AlbumPackagingUvGraphicInheritedCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicInheritedTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_UV_GRAPHIC_INHERITED_URL;
  }

  get model() {
    return AlbumPackagingUvGraphicInheritedType;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'albumConfigurationId',
      'configurationUvGraphicElementSvgName'
    ];
  }
}

export class AlbumPackagingUvGraphicInheritedInfosCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicInheritedInfos';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_UV_GRAPHIC_INHERITED_INFOS_URL;
  }

  get model() {
    return AlbumPackagingUvGraphicInheritedInfos;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'packagingUvGraphicInheritedType',
      'albumPackagingConfigurationId'
    ];
  }
}

export class AlbumPackagingGraphicCollectionsCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicCollectionTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_UV_GRAPHIC_COLLECTIONS_URL;
  }

  get model() {
    return AlbumPackagingGraphicCollectionType;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'packagingUvGraphicType',
      'packagingUvGraphicThemeType',
      'packagingUvGraphicInheritedType'
    ];
  }
}

export class AlbumPackagingUvGraphicColorTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicColorTypes';
  }

  get url() {
    return conf.settings.ALBUM_PACKAGING_UV_GRAPHIC_COLORS_URL;
  }

  get model() {
    return AlbumPackagingUvGraphicColorType;
  }

  get loadParams() {
    return [
      'packagingType',
      'formatType',
      'packagingUvGraphicType',
      'packagingUvGraphicElementType',
      'packagingUvGraphicInheritedType'
    ];
  }
}
