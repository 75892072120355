import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PackagingUvGraphicInheritedTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'packagingUvGraphicInheritedType';
  }

  get label() {
    return this.i18n.gettext('inherited graphic');
  }

  get calculatePrice() {
    return false;
  }

  get showWidget() {
    this._showWidget = this.props.items && this.props.items.length > 1;
    return this._showWidget;
  }

  UNSAFE_componentWillReceiveProps() {
    //Change widget
    if (
      this.props.items &&
      this.props.items.length === 0 &&
      this.props.delegate.filters.packagingUvGraphicElementTypes &&
      this.props.delegate.filters.packagingUvGraphicElementTypes.length === 0
    ) {
      this.props.delegate.clearPreviewSvg();
    }
  }
}
