import { GraphicCollapsableWidgetWithText } from '../../core/components/graphicCollapsableWidgetWithText';

export class PackagingUvGraphicColorWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'packagingUvGraphicColorType';
  }

  get label() {
    return this.i18n.gettext('colore decorazione');
  }

  get calculatePrice() {
    return false;
  }

  get showWidget() {
    this._showWidget = this.props.items && this.props.items.length > 1;
    return this._showWidget;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items && this.props.selected !== nextProps.selected) {
      this.setState({ isChosen: false }); //need to reopen the item selector
      this.collectErrors(nextProps);
    }

    //ned to set the new svg color
    if (nextProps.items && this.props.items !== nextProps.items) {
      if (nextProps.items.length === 0) {
        this.props.delegate.resetPreviewSvgColor();
        this.props.delegate.setPreviewSvg();
      }

      let setColor;
      nextProps.items.each(
        function (item) {
          if (this.props.delegate.configuration.packagingUvGraphicColorType) {
            setColor = nextProps.selected && item.id === nextProps.selected;
          } else {
            setColor = item.isDefault;
          }
          if (setColor) {
            this.props.delegate.chosePreviewSvgColor(item.colorCode);
            this.props.delegate.setPreviewSvgColor(item.colorCode);
            this.props.delegate.setPreviewSvg();
          }
        }.bind(this)
      );
    }
  }

  handleMouseOver(item) {
    this.props.delegate.setPreviewSvgColor(item.colorCode);
    this.props.delegate.setPreviewSvg();
  }

  handleMouseOut() {
    this.props.delegate.setPreviewSvgColor();
    this.props.delegate.setPreviewSvg();
  }

  handleClick(item) {
    super.handleClick(item);
    this.props.delegate.chosePreviewSvgColor(item.colorCode);
    this.props.delegate.setPreviewSvgColor(item.colorCode);
  }
}
