import React from 'react';
import { components } from 'outlinejs';

export class PackagingUvGraphicInheritedElementWidget extends components.BaseComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items && this.props.items.length === 1) {
      this.props.delegate.inheritedSvgInfos = this.props.items.models[0];
    }
    if (nextProps.items && this.props.items !== nextProps.items) {
      this.props.delegate.graphicsLoaded = false;
      if (nextProps.items.length === 0) {
        this.props.delegate.inheritedSvgInfos = null;
      }
    }
  }

  get name() {
    return 'packagingUvGraphicInheritedInfos';
  }

  render() {
    return <div></div>;
  }
}
