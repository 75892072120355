import { GraphicCollapsableWidgetWithText } from '../../core/components/graphicCollapsableWidgetWithText';

export class BagColorTypesWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'bagColorType';
  }

  get label() {
    return this.i18n.gettext('colore borsa');
  }

  get calculatePrice() {
    return false;
  }
}
