import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class AlbumPackagingConfiguration extends BaseModel {
  get urlRoot() {
    return conf.settings.ALBUM_PACKAGING_CONFIGURATIONS_URL;
  }

  getAbsoluteUrl() {
    return this.urlRoot + this.id + '/';
  }

  get id() {
    return this.get('id');
  }

  get configurationGuid() {
    return this.get('code');
  }

  get albumConfigurationId() {
    return this.get('configurationId');
  }

  get configurationId() {
    return this.get('configurationId');
  }

  set configurationId(value) {
    this.set('configurationId', value);
  }

  get productType() {
    return this.get('productType');
  }

  get coverEditorProjectId() {
    return this.get('coverEditorProjectId');
  }

  set coverEditorProjectId(value) {
    this.set('coverEditorProjectId', value);
  }

  get pagesNumber() {
    return this.get('pagesNumber');
  }

  set pagesNumber(value) {
    this.set('pagesNumber', value);
  }

  get formatType() {
    return this.get('formatType');
  }

  set formatType(value) {
    this.set('formatType', value);
  }

  get packagingType() {
    return this.get('packagingType');
  }

  set packagingType(value) {
    this.set('packagingType', value);
  }

  get bagColorType() {
    return this.get('bagColorType');
  }

  set bagColorType(value) {
    this.set('bagColorType', value);
  }

  get packagingUvGraphicType() {
    return this.get('packagingUvGraphicType');
  }

  set packagingUvGraphicType(value) {
    this.set('packagingUvGraphicType', value);
  }

  get packagingUvGraphicElementType() {
    return this.get('packagingUvGraphicElementType');
  }

  set packagingUvGraphicElementType(value) {
    this.set('packagingUvGraphicElementType', value);
  }

  get packagingUvGraphicThemeType() {
    return this.get('packagingUvGraphicThemeType');
  }

  set packagingUvGraphicThemeType(value) {
    this.set('packagingUvGraphicThemeType', value);
  }

  get packagingUvGraphicColorType() {
    return this.get('packagingUvGraphicColorType');
  }

  set packagingUvGraphicColorType(value) {
    this.set('packagingUvGraphicColorType', value);
  }

  get packagingUvGraphicTexts() {
    return this.get('packagingUvGraphicTexts');
  }

  set packagingUvGraphicTexts(value) {
    this.set('packagingUvGraphicTexts', value);
  }

  // get bagColor() {
  //   return this.get('bagColor');
  // }
  //
  // set bagColor(value) {
  //   this.set('bagColor', value);
  // }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get previewImageUrl() {
    return this.get('previewImageUrl');
  }

  set previewImageUrl(value) {
    this.set('previewImageUrl', value);
  }

  get serviceConfigurationTypeCode() {
    return this.get('serviceConfigurationTypeCode');
  }

  set serviceConfigurationTypeCode(value) {
    this.set('serviceConfigurationTypeCode', value);
  }

  get packagingUvGraphicInheritedType() {
    return this.get('packagingUvGraphicInheritedType');
  }

  set packagingUvGraphicInheritedType(value) {
    this.set('packagingUvGraphicInheritedType', value);
  }

  get packagingUvGraphicCollectionType() {
    return this.get('packagingUvGraphicCollectionType');
  }

  set packagingUvGraphicCollectionType(value) {
    this.set('packagingUvGraphicCollectionType', value);
  }

  get packagingUvGraphicElementSvgName() {
    return this.get('packagingUvGraphicElementSvgName');
  }

  set packagingUvGraphicElementSvgName(value) {
    this.set('packagingUvGraphicElementSvgName', value);
  }

  get previewStatus() {
    return this.get('previewStatus');
  }

  set previewStatus(value) {
    this.set('previewStatus', value);
  }

  get configurationUvGraphicTexts() {
    return this.get('configurationUvGraphicTexts');
  }

  get configurationUvGraphicElementSvgName() {
    return this.get('configurationUvGraphicElementSvgName');
  }

  get configurationUvGraphicElementSvgUrl() {
    return this.get('configurationUvGraphicElementSvgUrl');
  }
}

export class AlbumPackagingProduct extends BaseModel {
  get id() {
    return this.get('code');
  }

  get frontAssetType() {
    return this.get('frontAssetType');
  }

  get frontAssetUrl() {
    return this.get('frontAssetUrl');
  }

  get uvGraphicColor() {
    return this.get('uvGraphicColor');
  }

  get svgAssetType() {
    return this.get('svgAssetType');
  }

  get svgAssetUrl() {
    return this.get('svgAssetUrl');
  }

  get isOutOfStock() {
    return this.get('isOutOfStock');
  }

  set isOutOfStock(value) {
    this.set('isOutOfStock', value);
  }
}

export class AlbumPackagingPrice extends BaseModel {
  get price() {
    return this.get('price');
  }

  get formattedPrice() {
    return this.get('formattedPrice');
  }

  get currency() {
    return this.get('currency');
  }
}

export class AlbumPackagingType extends BaseModel {
  static get modelName() {
    return 'packagingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }
}

export class AlbumBagColorType extends BaseModel {
  static get modelName() {
    return 'bagColorType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }
}

export class AlbumPackagingUvGraphicType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPackagingUvGraphicThemeType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicThemeType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPackagingUvGraphicInheritedType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicInheritedType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPackagingUvGraphicInheritedInfos extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicInheritedInfos';
  }

  get uvGraphicElementSvgName() {
    return this.get('uvGraphicElementSvgName');
  }

  get uvGraphicElementSvgUrl() {
    return this.get('uvGraphicElementSvgUrl');
  }

  get vGraphicTexts() {
    return this.get('vGraphicTexts');
  }

  get uvGraphicColor() {
    let color = this.get('uvGraphicColor');
    if (color) {
      return `#${color}`;
    } else {
      return this.get('uvGraphicColor');
    }
  }
}

export class AlbumPackagingGraphicCollectionType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicCollectionType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPackagingUvGraphicElementType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicElementType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get svgUrl() {
    return this.get('svgUrl');
  }

  get svgName() {
    return this.get('svgName');
  }
}

export class AlbumPackagingUvGraphicText extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicTexts';
  }

  /*
   * Need for maintain old texts widget
   */
  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get codeRow2() {
    return this.get('codeRow2');
  }

  get nameRow2() {
    return this.get('nameRow2');
  }
  /*
   * End old part
   */

  get id() {
    return this.get('rowCode');
  }

  get rowCode() {
    return this.get('rowCode');
  }

  get rowNumber() {
    return this.get('rowNumber');
  }

  get name() {
    return this.get('name');
  }

  get minTextLength() {
    return this.get('minTextLength');
  }

  get maxTextLength() {
    return this.get('maxTextLength');
  }

  get fieldType() {
    return this.get('fieldType');
  }

  get contentType() {
    return this.get('contentType');
  }

  get isRequired() {
    return this.get('isRequired');
  }
}

export class PackagingInfo extends BaseModel {
  get marginTop() {
    return this.get('marginTop');
  }

  get marginBottom() {
    return this.get('marginBottom');
  }

  get marginLeft() {
    return this.get('marginLeft');
  }

  get marginRight() {
    return this.get('marginRight');
  }

  get visibleWidth() {
    return this.get('visibleWidth');
  }

  get visibleHeight() {
    return this.get('visibleHeight');
  }
}

export class AlbumPackagingCoverPhotoType extends BaseModel {
  static get modelName() {
    return 'coverPhotoType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPackagingUvGraphicColorType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicColorType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get colorCode() {
    let color = this.get('colorCode');
    return `#${color}`;
  }
}

export class BoxWithPhotosProject extends BaseModel {
  get urlRoot() {
    return conf.settings.BOX_WITH_PHOTOS_EDITOR_PROJECT_URL;
  }

  get id() {
    return this.get('id');
  }

  get projectType() {
    return this.get('projectType');
  }

  set projectType(value) {
    this.set('projectType', value);
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get language() {
    return this.get('language');
  }

  set language(value) {
    this.set('language', value);
  }

  get productConfigurationId() {
    return this.get('productConfigurationId');
  }

  set productConfigurationId(value) {
    this.set('productConfigurationId', value);
  }

  get productConfigurationType() {
    return this.get('productConfigurationType');
  }

  set productConfigurationType(value) {
    this.set('productConfigurationType', value);
  }

  get coverId() {
    return this.get('coverId');
  }

  set coverId(value) {
    this.set('coverId', value);
  }

  get init() {
    return this.get('init');
  }

  set init(value) {
    this.set('init', value);
  }

  get saved() {
    return this.get('saved');
  }

  set saved(value) {
    this.set('saved', value);
  }

  get valid() {
    return this.get('valid');
  }

  set valid(value) {
    this.set('valid', value);
  }
}
