import { conf } from 'outlinejs';

import CoverEditorBaseController from '../core/coverEditorBaseController';

import { BoxWithPhotosProject } from './models';

/*
 * Class handler for BoxWithPhotosProject
 * */
export default class extends CoverEditorBaseController {
  constructor(...args) {
    super(...args);

    this.coverEditorProjectClass = BoxWithPhotosProject;
    this.applicationBaseUrl = conf.settings.BOX_WITH_PHOTOS_EDITOR_APPLICATION_URL;
  }
}
