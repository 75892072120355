import { BaseCoverEditorWidget } from '../../core/components/baseCoverEditorWidget';

export class BoxWithPhotosEditorWidget extends BaseCoverEditorWidget {
  get label() {
    return this.i18n.gettext('personalizza la confezione');
  }

  get buttonLabel() {
    return this.i18n.gettext("Apri l'editor della confezione");
  }
}
