import { BaseConfiguratorProductStorage } from '../core/storage/index';
import { AlbumPackagingConfiguration } from './models';

export class AlbumPackagingStorage extends BaseConfiguratorProductStorage {
  static get productPrefixKey() {
    return 'AlbumPackaging';
  }
}

export async function getAlbumPackagingConfiguration(configurationId, params) {
  let configuration = null;
  if (!configurationId) {
    configurationId = await AlbumPackagingStorage.getConfigurationPk();
  }

  if (configurationId) {
    configuration = new AlbumPackagingConfiguration({ id: configurationId });
    if (params) {
      await configuration.fetch({ data: params });
    } else {
      await configuration.fetch();
    }
  }

  return configuration;
}
