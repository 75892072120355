import React from 'react';
import { conf } from 'outlinejs';
import Logger from '../core/logger';

import { BaseContentView, BaseProductView, BaseFiltersView } from '../core/views';

import { AlbumPackagingMaterialPreview } from './components/albumPackagingMaterialPreview';

import { PackagingTypesWidget } from './components/packagingTypesWidget';
import { PackagingUvGraphicThemeWidget } from './components/packagingUvGraphicThemeWidget';
import { PackagingUvGraphicElementWidget } from './components/packagingUvGraphicElementWidget';
import { PackagingUvGraphicTextWidget } from './components/packagingUvGraphicTextWidget';
import { BagColorTypesWidget } from './components/bagColorTypesWidget';
import { PackagingUvGraphicColorWidget } from './components/packagingUvGraphicColorWidget';
import { BoxWithPhotosEditorWidget } from './components/boxWithPhotosEditorWidget';
import { PackagingUvGraphicInheritedTypesWidget } from './components/packagingUvGraphicInheritedTypesWidget';
import { PackagingUvGraphicInheritedElementWidget } from './components/packagingUvGraphicInheritedElementWidget';
import { PackagingGraphicCollectionWidget } from './components/packagingGraphicCollectionWidget';
import { PackagingUvGraphicTypesWidget } from './components/packagingUvGraphicTypesWidget';
import { FiltersTitle } from '../core/components/filtersTitle';
import { OutOfStockAlert } from '../core/components/OutOfStockAlert';

class ProductView extends BaseProductView {
  priceIsLoading() {
    let priceIsLoading;
    if (this.props.updatePrice) {
      priceIsLoading = false;
    } else {
      priceIsLoading =
        this.props.loadingCollection.eventBookPackagingPrice ||
        this.props.productConfigurationIsLoading;
    }
    return priceIsLoading;
    //return this.props.loadingCollection.eventBookPackagingPrice || this.props.productConfigurationIsLoading;
  }

  renderPreview() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <AlbumPackagingMaterialPreview
            product={this.props.product}
            height={this.state.productPreviewHeight}
            width={this.state.productPreviewWidth}
          />
        </div>
      </div>
    );
  }
}

class FiltersView extends BaseFiltersView {
  getFilters() {
    const outOfStockVisible =
      conf.settings.OUT_OF_STOCK_ALBUM_PACKAGING_FORMATS.indexOf(
        this.props.configuration.formatType
      ) !== -1;

    return [
      <FiltersTitle key="FiltersTitle" title={this.i18n.gettext('Personalizza la confezione')} />,

      <div key={'outOfStockAlert'}>
        {outOfStockVisible && (
          <OutOfStockAlert
            className={' '}
            msg={conf.settings.OUT_OF_STOCK_ALBUM_PACKAGING_MESSAGE[this.request.language]}
          />
        )}
      </div>,

      <PackagingTypesWidget
        key="PackagingTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingTypes}
        selected={this.props.configuration.packagingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicInheritedTypesWidget
        key="PackagingUvGraphicInheritedTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicInheritedTypes}
        selected={this.props.configuration.packagingUvGraphicInheritedType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicInheritedElementWidget
        key="PackagingUvGraphicInheritedElementWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicInheritedInfos}
        loadingCollection={this.props.loadingCollection}
      />,

      <BagColorTypesWidget
        key="BagColorTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.bagColorTypes}
        selected={this.props.configuration.bagColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicTypesWidget
        key="PackagingUvGraphicTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicTypes}
        selected={this.props.configuration.packagingUvGraphicType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicThemeWidget
        key="PackagingUvGraphicThemeWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicThemeTypes}
        selected={this.props.configuration.packagingUvGraphicThemeType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingGraphicCollectionWidget
        key="PackagingGraphicCollectionWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicCollectionTypes}
        selected={this.props.configuration.packagingUvGraphicCollectionType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicElementWidget
        key="PackagingUvGraphicElementWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicElementTypes}
        selected={this.props.configuration.packagingUvGraphicElementSvgName}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicTextWidget
        key="PackagingUvGraphicTextWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicTextsCollection}
        value={this.props.configuration.packagingUvGraphicTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicColorWidget
        key="PackagingUvGraphicColorWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicColorTypes}
        selected={this.props.configuration.packagingUvGraphicColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <BoxWithPhotosEditorWidget
        key="BoxWithPhotosEditorWidget"
        delegate={this.delegate}
        items={this.props.filters.coverPhotoTypes}
        showCoverEditor={this.delegate.showCoverEditor}
        coverEditorApplicationUrl={this.delegate.coverEditorUrl}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />
    ];
  }
}

export class AlbumPackagingContentView extends BaseContentView {
  render() {
    return super.render(ProductView, FiltersView);
  }

  constructor(props) {
    super(props);
    this.handleIFrameMessage = this.handleIFrameMessage.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIFrameMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIFrameMessage, false);
  }

  handleIFrameMessage(event) {
    if (event.data.message === 'CloseAlbumBoxEditorProject') {
      Logger.info('CloseAlbumBoxEditorProject', 'received');
      this.delegate.hideCoverEditorIframe();
    }

    if (event.data.message === 'SaveAlbumBoxEditorProject') {
      Logger.info('SaveAlbumBoxEditorProject', 'received');
      this.delegate.saveCoverEditorProject(event.data.data.projectId);
      this.delegate.hideCoverEditorIframe();
    }
  }
}
