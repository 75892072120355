import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { BaseConfiguratorController } from '../core/controllers';
import {
  GuiErrors,
  GuiNotifications,
  createPreOrder,
  mergeQueryParams,
  navigateTo
} from '../core/utils/index';
import Logger from '../core/logger';
import { albumFunnelSteps } from '../core/utils/breadcrumb';
import axios from 'axios';

import { AlbumPackagingContentView } from './views';
import {
  AlbumPackagingTypeCollection,
  AlbumPackagingBagColorsTypesCollection,
  AlbumPackagingUvGraphicThemeCollection,
  AlbumPackagingUvGraphicElementCollection,
  AlbumPackagingUvGraphicTextsCollection,
  AlbumPackagingProductCollection,
  AlbumPackagingPriceCollection,
  AlbumPackagingCoverPhotoTypesCollection,
  AlbumPackagingUvGraphicColorTypesCollection,
  AlbumPackagingUvGraphicInheritedCollection,
  AlbumPackagingGraphicCollectionsCollection,
  AlbumPackagingUvGraphicTypesCollection,
  AlbumPackagingUvGraphicInheritedInfosCollection
} from './managers';
import { AlbumPackagingConfiguration } from './models';
import { AlbumPackagingStorage, getAlbumPackagingConfiguration } from './utils';
import { getAlbumConfiguration } from '../album/utils';
import { AlbumProjectStorage } from '../core/storage/index';
import { PackagingConfigurationError, InvalidPackagingConfigurationError } from './errors';
import BoxWithPhotosEditor from './boxWithPhotosEditor';
import { ProfessionalPreOrdersCollection } from '../core/managers';
import { AlbumPriceCollection } from '../album/managers';
import {
  getConfigurationOptionalPriceParams,
  getConfigurationRequiredPriceParams
} from '../album/utils';
import EventTracker, { productCategoryInterface, productInterface } from '../core/eventTracker';

/**
 * Main controller for album packaging
 * */
export class AlbumPackagingController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get funnelStep() {
    return 2;
  }

  beforeNavigateBreadcrumbFunction() {
    this.saveProductConfiguration();
  }

  get funnelSteps() {
    return albumFunnelSteps(this.request);
  }

  getCallToAction() {
    return this.i18n.gettext('Procedi');
  }

  getTitle() {
    return this.i18n.gettext('Scatola');
  }

  get context() {
    return Object.assign(super.context, {
      product: this.product,
      configuration: this.configuration,
      filters: this.filters,
      filtersViewValidity: this.filtersViewValidity,
      priceValidity: this.priceValidity,
      errorsVisibility: this.errorsVisibility,
      initViewRendering: this.initViewRendering,
      loadingCollection: this.loadingCollection,
      loadingPage: this.loadingPage,
      productConfigurationIsLoading: this.productConfigurationIsLoading,
      filtersViewIsLoading: this.filtersViewIsLoading,
      user: this.user,
      customerUser: this.customerUser,
      showCoverEditor: this.showCoverEditor,
      coverEditorUrl: this.coverEditorUrl,
      coverEditorProjectIsValid: this.coverEditorProjectIsValid,
      updatePrice: this.updatePrice,
      coverEditorController: this.coverEditorController,
      coverEditorNotConfigured: this.coverEditorNotConfigured,
      inheritedSvgInfos: this.inheritedSvgInfos
    });
  }

  get view() {
    return AlbumPackagingContentView;
  }

  async getAlbumPreOrder() {
    return await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      this.request.query.configurationId,
      conf.settings.ALBUM_PRODUCT_TYPE
    );
  }

  async getAlbumPackagingPreOrder() {
    return await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      this.request.query.packagingConfigurationId,
      conf.settings.ALBUM_PACKAGING_PRODUCT_TYPE
    );
  }

  async initPreOrders() {
    if (!(this.request.query.configurationId && this.request.query.packagingConfigurationId)) {
      return [null, null];
    }

    let preOrders = await Promise.all([this.getAlbumPreOrder(), this.getAlbumPackagingPreOrder()]);

    if (preOrders.length > 0 && preOrders[0].first() && preOrders[1].first()) {
      return [preOrders[0].first().id, preOrders[1].first().id, preOrders[0].first().projectId];
    }
    return [null, null, null];
  }

  async initContentProps() {
    super.initContentProps();

    this.product = {
      productItem: {},
      previewUrl: null,
      previewSvg: null,
      previewSvgUrl: null,
      previewTexLimit1: null,
      previewTexLimit2: null,
      productConfiguration: new AlbumPackagingConfiguration(),
      svgColor: null,
      chosenSvgColor: null,
      price: {},
      showWidgetPrice: this.customerUserIsAuthorized,
      productPrice: {}
    };

    this.coverEditorNotConfigured = false;
    this.inheritedSvgInfos = null;
    this.showNotify = false;
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      await this.loadInitialConfiguration();
      await this.loadProductConfigurationPrice();

      this.reloadView();

      EventTracker.log(
        this.customerUser,
        'configurator_packaging_view',
        productInterface(this.product.productConfiguration)
      );
    }
  }

  async loadInitialConfiguration() {
    try {
      let packagingConfigurationId = this.request.query.packagingConfigurationId;
      let packagingConfiguration = await getAlbumPackagingConfiguration(packagingConfigurationId, {
        includeAlbumInfo: true
      });
      if (!packagingConfiguration) {
        throw new InvalidPackagingConfigurationError(
          'Impossible to load configuration from local storage'
        );
      }
      this.product.productConfiguration = packagingConfiguration;
      this.configuration = packagingConfiguration.toJSON(); // la configurazione è un JSON (NON un backbone model)

      await this.initCoverEditorController();
    } catch (error) {
      if (error.code === 404) {
        GuiErrors.modalInfo(
          this.i18n.gettext(
            'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
          )
        );
        // this.response.navigate('album:new', {});
        navigateTo(this.request, this.response, 'album:new', {}, false);
      } else {
        if (error instanceof InvalidPackagingConfigurationError) {
          let timestamp = new Date().getTime();
          GuiErrors.modalError(
            this.i18n.gettext(
              'Non è stato possibile caricare la configurazione della scatola, si prega di ripetere la procedura.'
            ),
            'Error detail: loadPackagingConfiguration - code: ' + timestamp
          );
          // this.response.navigate('album:main', {});
          navigateTo(this.request, this.response, 'album:main', {}, false, this.request.query);
        } else {
          new PackagingConfigurationError(`AlbumPackagingController.init`, { //eslint-disable-line
            packagingConfiguration: this.configuration,
            error: error
          });
        }
      }
    } finally {
      this.stopInitialRendering();
    }
  }

  async loadProductConfigurationPrice() {
    let productConfiguration;
    try {
      productConfiguration = await getAlbumConfiguration(this.request.query.configurationId);
    } catch (error) {
      if (error.code === 404) {
        GuiErrors.modalInfo(
          this.i18n.gettext(
            'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
          )
        );
        navigateTo(this.request, this.response, 'album:new', {}, false, null);
      } else {
        new PackagingConfigurationError('AlbumPackagingController.loadProductConfigurationPrice - failed to fetch Album productConfiguration', { //eslint-disable-line
            packagingConfiguration: this.configuration,
            productConfigurationId: this.request.query.configurationId,
            error: error
          }
        );
      }
    } finally {
      this.stopInitialRendering();
    }

    if (productConfiguration) {
      let priceOptionalParams = await getConfigurationOptionalPriceParams(
        null,
        productConfiguration
      );
      let priceRequiredParams = await getConfigurationRequiredPriceParams(productConfiguration);
      priceRequiredParams = Object.assign(priceRequiredParams, {
        pagesNumber: productConfiguration.pagesNumber,
        shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null
      });

      let priceParams = Object.assign(priceRequiredParams, priceOptionalParams);

      try {
        let price = await this.getPrice(new AlbumPriceCollection(), priceParams);
        this.productPrice = price;
        this.setProductConfigurationPrice(price);
        this.setProductConfigurationName(productConfiguration.productName);
      } catch (error) {
        Logger.error('AlbumPackaging - Loading product price error', {
          error: error,
          priceParams: priceParams,
          productConfiguration: productConfiguration
        });
      }
    }
  }

  getPreviewTexts() {
    return this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length === 0
      ? this.configuration.configurationUvGraphicTexts
      : this.configuration.packagingUvGraphicTexts;
  }

  async reloadView(getPrice = true) {
    this.productConfigurationIsLoading = true;
    this.filtersViewIsLoading = true;
    if (getPrice) {
      this.updatePrice = false;
    }
    this.render(this.context);
    try {
      this.resetFilterErrorsCollection();

      await this.loadCollection(new AlbumPackagingTypeCollection());
      /*
       * Inizializzare la fase di rendering prima dei widget UV, altrimenti non vengono caricati gli SVG
       * */

      await Promise.all([
        this.loadCollection(new AlbumPackagingBagColorsTypesCollection()),
        this.loadCollection(new AlbumPackagingCoverPhotoTypesCollection()),
        this.loadCollection(new AlbumPackagingUvGraphicInheritedCollection())
      ]);

      await Promise.all([
        this.loadCollection(new AlbumPackagingUvGraphicTypesCollection()),
        this.loadCollection(new AlbumPackagingUvGraphicInheritedInfosCollection())
      ]);

      await this.loadCollection(new AlbumPackagingUvGraphicThemeCollection());

      await this.loadProduct();

      await this.loadCollection(new AlbumPackagingGraphicCollectionsCollection());

      await this.loadUvGraphicElementsCollection(new AlbumPackagingUvGraphicElementCollection(), {
        uvGraphicElementSvgName: 'packagingUvGraphicElementSvgName',
        uvGraphicElementType: 'packagingUvGraphicElementType'
      });

      await Promise.all([
        this.loadCollection(new AlbumPackagingUvGraphicColorTypesCollection()),
        this.loadCollection(new AlbumPackagingUvGraphicTextsCollection())
      ]);

      await this.loadCoverEditorController();
    } catch (err) {
      Logger.error('AlbumPackagingController.reloadView-loadFilters', {
        error: err,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    } finally {
      this.filtersViewIsLoading = false;
      this.render(this.context);
    }

    this.getPriceForCurrentConfiguration(getPrice);
  }

  async getPriceForCurrentConfiguration(getPrice) {
    if (this.filters === []) {
      let timestamp = new Date().getTime();
      Logger.error('AlbumPackagingController.reloadView-emptyfilters', {
        errorCode: timestamp,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    } else {
      try {
        this.loadProduct(true);
        if (getPrice) {
          this.loadPrice();
        }
      } catch (err) {
        Logger.error('AlbumPackagingController.reloadView-loadProduct', {
          error: err,
          configuration: this.configuration,
          productConfiguration: this.product.productConfiguration,
          filters: this.filters
        });
      } finally {
        this.productConfigurationIsLoading = false;
        this.updatePrice = true;
        this.render(this.context);
      }
    }
  }

  async loadProduct(setSvg = false) {
    let productParams = {
      formatType: this.configuration.formatType
    };

    if (this.filters.packagingTypes && this.filters.packagingTypes.length > 0) {
      productParams.packagingType = this.configuration.packagingType;
    }

    if (this.filters.bagColorTypes && this.filters.bagColorTypes.length > 0) {
      productParams.bagColorType = this.configuration.bagColorType;
    }

    if (this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0) {
      productParams.packagingUvGraphicType = this.configuration.packagingUvGraphicType;
    }

    if (
      this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length > 0
    ) {
      productParams.packagingUvGraphicElementType =
        this.configuration.packagingUvGraphicElementType;
    }

    if (
      this.filters.packagingUvGraphicThemeTypes &&
      this.filters.packagingUvGraphicThemeTypes.length > 0
    ) {
      productParams.packagingUvGraphicThemeType = this.configuration.packagingUvGraphicThemeType;
    }

    if (
      this.filters.packagingUvGraphicColorTypes &&
      this.filters.packagingUvGraphicColorTypes.length > 0
    ) {
      productParams.packagingUvGraphicColorType = this.configuration.packagingUvGraphicColorType;
    }

    if (this.filters.packagingPhotoTypes && this.filters.packagingPhotoTypes.length > 0) {
      productParams.packagingPhotoType = this.configuration.packagingPhotoType;
    }

    if (this.filters.packagingStandardTypes && this.filters.packagingStandardTypes.length > 0) {
      productParams.packagingStandardType = this.configuration.packagingStandardType;
    }

    if (
      this.filters.packagingUvGraphicInheritedTypes &&
      this.filters.packagingUvGraphicInheritedTypes.length > 0
    ) {
      productParams.packagingUvGraphicInheritedType =
        this.configuration.packagingUvGraphicInheritedType;
    }

    if (
      this.filters.packagingUvGraphicCollectionTypes &&
      this.filters.packagingUvGraphicCollectionTypes.length > 0
    ) {
      productParams.packagingUvGraphicCollectionType =
        this.configuration.packagingUvGraphicCollectionType;
    }

    if (
      this.filters.packagingUvGraphicTextsCollection &&
      this.filters.packagingUvGraphicTextsCollection.length > 0
    ) {
      productParams.packagingUvGraphicTexts = this.configuration.packagingUvGraphicTexts;
    }

    const product = await this.getProduct(new AlbumPackagingProductCollection(), productParams);
    if (product) {
      product.isOutOfStock = conf.settings.OUT_OF_STOCK_ALBUM_PACKAGING_TYPES.includes(
        productParams.packagingType
      );
      this.setAlbumPackagingProduct(product, setSvg);
    }
  }

  async loadPrice() {
    let priceParams = {
      formatType: this.configuration.formatType,
      pagesNumber: this.configuration.pagesNumber,
      packagingType: this.configuration.packagingType,
      language: this.getLanguage(),
      albumConfigurationId: this.configuration.configurationId
    };

    if (this.customerUserIsAuthorized) {
      priceParams.shopCode = this.customerUser.shopCode;
    }

    if (this.filters.bagColorTypes && this.filters.bagColorTypes.length > 0) {
      priceParams.bagColorType = this.configuration.bagColorType;
    }

    if (this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0) {
      priceParams.packagingUvGraphicType = this.configuration.packagingUvGraphicType;
    }

    if (
      this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length > 0
    ) {
      priceParams.packagingUvGraphicElementType = this.configuration.packagingUvGraphicElementType;
    }

    if (
      this.filters.packagingUvGraphicThemeTypes &&
      this.filters.packagingUvGraphicThemeTypes.length > 0
    ) {
      priceParams.packagingUvGraphicThemeType = this.configuration.packagingUvGraphicThemeType;
    }

    if (
      this.filters.packagingUvGraphicColorTypes &&
      this.filters.packagingUvGraphicColorTypes.length > 0
    ) {
      priceParams.packagingUvGraphicColorType = this.configuration.packagingUvGraphicColorType;
    }

    if (this.filters.packagingPhotoTypes && this.filters.packagingPhotoTypes.length > 0) {
      priceParams.packagingPhotoType = this.configuration.packagingPhotoType;
    }

    if (
      this.filters.packagingUvGraphicInheritedTypes &&
      this.filters.packagingUvGraphicInheritedTypes.length > 0
    ) {
      priceParams.packagingUvGraphicInheritedType =
        this.configuration.packagingUvGraphicInheritedType;
    }

    let price = await this.getPrice(new AlbumPackagingPriceCollection(), priceParams);
    if (price) {
      this.setConfigurationPrice(price);
    }
  }

  async setAlbumPackagingProduct(productItem, setSvg) {
    super.setConfigurationProduct(productItem);
    //TODO: verificare se serve
    // if (this.filters.packagingUvGraphicInheritedTypes && this.filters.packagingUvGraphicInheritedTypes.length !== 0 && this.filters.packagingUvGraphicElementTypes && this.filters.packagingUvGraphicElementTypes.length === 0) {
    //   this.setPreviewSvg(this.configuration.configurationUvGraphicElementSvgUrl);
    // }
    if (this.inheritedSvgInfos && setSvg) {
      this.setPreviewSvg(
        this.inheritedSvgInfos.uvGraphicElementSvgUrl,
        this.inheritedSvgInfos.vGraphicTexts,
        this.inheritedSvgInfos.uvGraphicColor
      );
    }
    this.render(this.context);
  }

  getPreparedConfiguration(configuration) {
    let shopcode = null;
    if (this.customerUserIsAuthorized) {
      shopcode = this.customerUser.shopCode;
    }

    if (!configuration) {
      configuration = this.configuration;
    }
    configuration.shopCode = shopcode;

    configuration.coverEditorProjectId = this.product.productConfiguration.coverEditorProjectId;
    configuration.bagColorType =
      this.filters.bagColorTypes && this.filters.bagColorTypes.length > 0
        ? configuration.bagColorType
        : null;
    configuration.packagingUvGraphicType =
      this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0
        ? configuration.packagingUvGraphicType
        : null;
    configuration.packagingUvGraphicInheritedType =
      this.filters.packagingUvGraphicInheritedTypes &&
      this.filters.packagingUvGraphicInheritedTypes.length > 0
        ? configuration.packagingUvGraphicInheritedType
        : null;
    configuration.packagingUvGraphicElementType =
      this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length > 0
        ? configuration.packagingUvGraphicElementType
        : null;
    configuration.packagingUvGraphicThemeType =
      this.filters.packagingUvGraphicThemeTypes &&
      this.filters.packagingUvGraphicThemeTypes.length > 0
        ? configuration.packagingUvGraphicThemeType
        : null;
    configuration.packagingUvGraphicColorType =
      this.filters.packagingUvGraphicColorTypes &&
      this.filters.packagingUvGraphicColorTypes.length > 0
        ? configuration.packagingUvGraphicColorType
        : null;
    configuration.packagingUvGraphicTexts =
      this.filters.packagingUvGraphicTextsCollection &&
      this.filters.packagingUvGraphicTextsCollection.length > 0
        ? configuration.packagingUvGraphicTexts
        : {};
    configuration.packagingUvGraphicCollectionType =
      this.filters.packagingUvGraphicCollectionTypes &&
      this.filters.packagingUvGraphicCollectionTypes.length > 0
        ? configuration.packagingUvGraphicCollectionType
        : null;
    //PATCH for old texts
    if ('1' in configuration.packagingUvGraphicTexts) {
      delete configuration.packagingUvGraphicTexts['1'];
    }
    if ('2' in configuration.packagingUvGraphicTexts) {
      delete configuration.packagingUvGraphicTexts['2'];
    }
    if ('3' in configuration.packagingUvGraphicTexts) {
      delete configuration.packagingUvGraphicTexts['3'];
    }
    if ('4' in configuration.packagingUvGraphicTexts) {
      delete configuration.packagingUvGraphicTexts['4'];
    }
    if ('5' in configuration.packagingUvGraphicTexts) {
      delete configuration.packagingUvGraphicTexts['5'];
    }
    if ('6' in configuration.packagingUvGraphicTexts) {
      delete configuration.packagingUvGraphicTexts['6'];
    }

    return configuration;
  }

  async saveProductConfiguration() {
    try {
      let currentConfiguration = this.getPreparedConfiguration();
      let productConfiguration = this.product.productConfiguration;
      let configuration = await productConfiguration.save(currentConfiguration);
      Logger.info('AlbumPackaging Configuration id:', this.product.productConfiguration.id);
      await AlbumPackagingStorage.setConfigurationPk(this.product.productConfiguration.id);
      return configuration;
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('AlbumPackagingController.saveProductConfiguration', {
        error: err,
        errorCode: timestamp,
        configuration: this.product.productConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la configurazione, si prega di ripetere la procedura.'
        ),
        'Error detail: saveProductConfiguration - code: ' + timestamp
      );
      // this.response.navigate('album-packaging:main', {});
      navigateTo(
        this.request,
        this.response,
        'album-packaging:main',
        {},
        false,
        this.request.query
      );
      return false;
    }
  }

  async savePreorder() {
    /** check if preorder already exist **/
    [this.preOrderId, this.packagingPreOrderId, this.projectId] = await this.initPreOrders();
    if (this.preOrderId && this.packagingPreOrderId && this.projectId) {
      return [true, undefined];
    }

    /** get stored configurations **/
    let albumConfiguration = await getAlbumConfiguration(this.request.query.configurationId);
    let packagingConfiguration = this.product.productConfiguration;
    if (!albumConfiguration || !packagingConfiguration) {
      let timestamp = new Date().getTime();
      GuiErrors.modalError(
        this.i18n.gettext(
          'Qualcosa è andato storto. Ti preghiamo di riprovare riconfigurando un nuovo prodotto.'
        ),
        'Error detail: preorder creation no configuration found - code: ' + timestamp
      );
      navigateTo(this.request, this.response, 'album:new', {}, false, null);
      return [false, new Error('Configurations not found')];
    }

    let timestamp = new Date().getTime();

    /** patch shopcodes for anonymous configurations**/
    if (!albumConfiguration.shopCode || !packagingConfiguration.shopCode) {
      try {
        // updates only album configuration. Packaging is automatically updated
        albumConfiguration.shopCode = this.customerUser.shopCode;
        await albumConfiguration.save();
      } catch (err) {
        Logger.error(
          'Album savePreorder.updateConfigurationsShopCode - Unable to update configurations',
          {
            error: err,
            errorCode: timestamp,
            user: this.user,
            customerUser: this.customerUser,
            albumConfiguration: albumConfiguration,
            packagingConfiguration: packagingConfiguration
          }
        );
        GuiErrors.modalError(
          this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
          'Error detail: updateConfigurations - code: ' + timestamp
        );
        return [false, err];
      }
    }

    /** Create Pre-Orders **/
    try {
      let albumPreorder = await createPreOrder(
        this.customerUser,
        'Album',
        albumConfiguration,
        null,
        albumConfiguration.configurationGuid
      );
      this.preOrderId = albumPreorder.id;
      await AlbumProjectStorage.setPk(albumPreorder.projectId);
      this.projectId = albumPreorder.projectId;
      let albumPackagingPreorder = await createPreOrder(
        this.customerUser,
        'Album',
        packagingConfiguration,
        albumPreorder.projectId,
        packagingConfiguration.configurationGuid
      );
      this.packagingPreOrderId = albumPackagingPreorder.id;
      this.showNotify = true;

      EventTracker.log(this.customerUser, 'bucket_add_success', {
        product_category: productCategoryInterface(this.product.productConfiguration.productType),
        project_id: this.projectId
      });

      return [true, undefined];
    } catch (err) {
      Logger.error('Album savePreorder.createPreOrder', {
        error: err,
        errorCode: timestamp,
        user: this.user,
        customerUser: this.customerUser,
        albumConfiguration: albumConfiguration,
        packagingConfiguration: packagingConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
        'Error detail: createPreOrder - code: ' + timestamp,
        this.i18n.gettext('Chiudi'),
        null,
        null
      );
      return [false, err];
    }
  }

  async goToNextStep() {
    this.errorsVisibility = true;
    this.startLoadingPage();

    if (this.hasCoverEditorWidget()) {
      if (
        !this.product.productConfiguration.coverEditorProjectId ||
        !this.isCoverEditorProjectValid()
      ) {
        this.coverEditorNotConfigured = true;
        this.stopLoadingPage();
        return;
      }
    }

    if (await this.checkWidgetErrors()) {
      let packagingConfiguration = await this.saveProductConfiguration();

      if (!this.isUserLoggedIn()) {
        this.redirectToLoginPage();
        return null;
      }

      if (this.customerUser && !this.customerUser.shopCode) {
        let timestamp = new Date().getTime();
        this.stopLoadingPage();
        GuiErrors.modalError(
          this.i18n.gettext('User not authorized to add product to cart'),
          'Error detail: shopCode null - code: ' + timestamp,
          this.i18n.gettext('Chiudi'),
          null,
          null
        );
        return false;
      }

      if (packagingConfiguration) {
        let [preOrderResult, error] = await this.savePreorder();
        if (preOrderResult) {
          navigateTo(
            this.request,
            this.response,
            'services:Album',
            {},
            false,
            mergeQueryParams(this.request.query, {
              preOrderId: this.preOrderId,
              packagingPreOrderId: this.packagingPreOrderId,
              professionalProjectId: this.projectId,
              showNotify: this.showNotify
            })
          );
        } else {
          EventTracker.log(this.customerUser, 'bucket_add_error', {
            product_category: productCategoryInterface(
              this.product.productConfiguration.productType
            ),
            error_code: String(error)
          });
        }
      }
    }
    this.stopLoadingPage();
  }

  /*********************************************************
   ***********  Photographic Packaging editor Start  *******
   *********************************************************/
  hasCoverEditorWidget() {
    return (
      this.filters &&
      this.filters.coverPhotoTypes &&
      this.filters &&
      this.filters.coverPhotoTypes.length > 0
    );
  }

  isCoverEditorProjectInitialized() {
    return (
      this.coverEditorController &&
      this.coverEditorController.coverEditorProjectModel &&
      this.coverEditorController.coverEditorProjectModel.init
    );
  }

  isCoverEditorProjectSaved() {
    return (
      this.product.productConfiguration && this.product.productConfiguration.coverEditorProjectId
    );
  }

  isCoverEditorProjectValid() {
    return (
      this.isCoverEditorProjectSaved() &&
      !this.isCoverEditorProjectInitialized() &&
      this.coverEditorController &&
      this.coverEditorController.coverEditorProjectModel &&
      this.coverEditorController.coverEditorProjectModel.valid
    );
  }

  async initCoverEditorController() {
    this.coverEditorController = new BoxWithPhotosEditor(
      this.request,
      this.product.productConfiguration
    );
  }

  async loadCoverEditorController() {
    if (this.hasCoverEditorWidget()) {
      if (
        this.product.productConfiguration &&
        this.product.productConfiguration.coverEditorProjectId
      ) {
        this.coverEditorController.coverEditorProjectModel =
          await this.coverEditorController.getCoverEditorProject(
            this.product.productConfiguration.coverEditorProjectId
          );
      }
    }
  }

  async resetCoverEditorProject() {
    Logger.info('RESET photographic project');

    // invalido il widget per notificare l'errore
    // this.coverEditorProjectIsValid = false;

    // ricarico i widget
    await this.reloadView();

    this.productConfigurationIsLoading = true;
    this.render(this.context);

    // salvo la nuova configurazione per forzare i nuovi dati produttivi
    await this.saveProductConfiguration(false);

    // eseguo una fetch del progetto per invalidare il modello
    await this.coverEditorController.resetCoverEditorProject();

    this.productConfigurationIsLoading = false;
    this.render(this.context);
  }

  /*
   * Returns the AeCoverEditor application url
   */

  async showCoverEditorIframe() {
    this.startLoadingPage();

    // resolve all errors before saving configuration
    await this.checkWidgetErrors();
    if (!this.filtersViewValidity && this.viewInstance.isCoverEditorWidgetBlocked()) {
      this.viewInstance.scrollToFirstWidgetErrorExceptCoverEditor();
      this.stopLoadingPage();
      return false;
    }

    // save configuration (now we have a valid configuration to save)
    let configuration = await this.saveProductConfiguration();
    if (!configuration) {
      this.stopLoadingPage();
      return false;
    }

    // log in user if necessary
    if (!this.isUserLoggedIn()) {
      this.redirectToLoginPage();
      return false;
    }

    // alert for the users without shopcode
    if (!this.customerUserIsAuthorized) {
      GuiErrors.modalInfo(this.i18n.gettext('Non hai permessi per aprire Editor'));
      this.stopLoadingPage();
      return false;
    }

    try {
      if (!this.isCoverEditorProjectSaved()) {
        // creo un progetto nuovo CoverEditor
        Logger.info('Create new project');
        await this.coverEditorController.createCoverEditorProject();
      }
    } catch (err) {
      this.stopLoadingPage();
      let timestamp = new Date().getTime();
      GuiErrors.modalError(
        this.i18n.gettext('Non è stato possibile aprire cover editor'),
        'Error detail: createCoverEditorProject - code: ' + timestamp
      );
      Logger.error('AlbumPackaging.createCoverEditorProject', {
        error: err,
        errorCode: timestamp,
        configuration: this.configuration
      });
      return false;
    }

    this.coverEditorUrl = await this.coverEditorController.getCoverEditorApplicationUrl();
    this.stopLoadingPage();

    this.showCoverEditor = this.coverEditorUrl !== null;
    this.render(this.context);
  }

  hideCoverEditorIframe() {
    this.showCoverEditor = false;
    this.render(this.context);
  }

  async saveCoverEditorProject(projectId) {
    try {
      // valido il widget per eliminare l'errore
      //this.coverEditorProjectIsValid = true;
      this.loadingPreview = true;
      this.coverEditorNotConfigured = false;
      this.render(this.context);

      // save cover editor project id into eventbook configuration
      this.product.productConfiguration.coverEditorProjectId = projectId;
      this.product.productConfiguration = await this.saveProductConfiguration(false);

      GuiNotifications.notificationInfo(
        this.i18n.gettext('La tua configurazione è stata salvata con successo'),
        10000
      );

      // syncronize model with last save
      await this.coverEditorController.syncCoverEditorProject();
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('AlbumController.saveCoverEditorProject', {
        error: err,
        errorCode: timestamp,
        projectId: projectId
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la copertina fotografica, si prega di ripetere la procedura.'
        ),
        'Error detail: rasterizeCoverSvg - code: ' + timestamp
      );
    } finally {
      this.loadingPreview = false;
      this.render(this.context);
    }
  }

  /*********************************************************
   ***********  Photographic Packaging editor END  *************
   *********************************************************/

  /*
   * Graphic UV left preview handling functions section
   */

  setPreviewImage(url) {
    this.product.previewUrl = url;
    this.render(this.context);
  }

  fixSvg(svg, texts, color = undefined) {
    this.fixSvgTexts(svg, texts);
    if (color) {
      this.fixSvgColor(svg, color);
    }

    /* save the svg xml element as ready to use text */
    let s = new XMLSerializer();
    return s.serializeToString(svg);
  }

  fixSvgColor(svg, color) {
    /*fix the svg color for all svg elements*/
    let allElements = svg.querySelectorAll('*');
    for (let i = 0; i < allElements.length; i++) {
      allElements[i].setAttribute('fill', color);
    }
  }

  fixSvgTexts(svg, texts) {
    if (
      this.isSvgTextTypedByUser() ||
      (this.getPreviewTexts() && Object.keys(this.getPreviewTexts()).length > 0)
    ) {
      let svgTextElements = svg.querySelectorAll('text');

      for (let i = 0; i <= svgTextElements.length; i++) {
        let svgTextElement = svgTextElements[i];

        if (svgTextElement) {
          let svgTextElementId = svgTextElement.getAttribute('id');

          if (svgTextElementId) {
            texts = this.getPreviewTexts()[svgTextElementId];
            let text = '';
            if (texts) {
              text = texts;
            }
            svgTextElement.childNodes[0].nodeValue = text;
          } else {
            console.error('SVG have text component without id');
          }
        }
      }
    }
  }

  async setPreviewSvg(svgUrl, texts, color = this.product.svgColor) {
    svgUrl = svgUrl || this.product.previewSvgUrl;
    if (svgUrl) {
      var request = axios;
      request
        .get(svgUrl)
        .then((response) => {
          var data = response.data;
          var doc = new DOMParser().parseFromString(data, 'text/html');
          let svg = doc.getElementsByTagName('svg')[0];
          this.product.previewSvg = this.fixSvg(svg, texts, color);
          this.render(this.context);
        })
        .catch(function (error) {
          Logger.error('setPreviewSvg - Error on SVG file retrieve', {
            url: svgUrl,
            error: error
          });
        });
    }
  }

  clearPreviewSvg() {
    this.product.previewSvgUrl = null;
    this.product.previewSvg = null;
  }

  chosePreviewSvg(svgUrl) {
    this.product.previewSvgUrl = svgUrl;
  }

  setPreviewSvgColor(color) {
    if (color) {
      this.product.svgColor = color;
    } else {
      this.product.svgColor = this.product.chosenSvgColor;
    }
  }

  chosePreviewSvgColor(color) {
    color = color || this.product.svgColor;
    this.product.chosenSvgColor = this.product.svgColor = color;
  }

  resetPreviewSvgColor() {
    this.product.svgColor = null;
    this.product.chosenSvgColor = null;
  }

  chosePreviewTexLimit(rowLimitMin, rowLimitMax) {
    this.product.previewTexLimitMin = rowLimitMin;
    this.product.previewTexLimitMax = rowLimitMax;
  }

  chosePreviewSvgText() {
    this.product.SvgTextTyped = true;
  }

  isSvgTextTypedByUser() {
    return this.product.SvgTextTyped;
  }

  /** END OF left preview handling functions section **/
}
