import { BaseUvGraphicTextWidget } from '../../core/components/baseAlbumUvGraphicTextsWidget';

export class PackagingUvGraphicTextWidget extends BaseUvGraphicTextWidget {
  get name() {
    return 'packagingUvGraphicTexts';
  }

  get label() {
    return this.i18n.gettext('testo uv');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    let maxLimit = 0;
    let minLimit = 0;

    if (nextProps.items && nextProps.items.map) {
      nextProps.items.map((item) => {
        maxLimit = item.maxTextLength || 0;
        minLimit = item.minTextLength || 0;
      });
    }

    this.props.delegate.chosePreviewTexLimit(minLimit, maxLimit);
  }

  async handleFocus(item) {
    //need to handle date errors
    this.decrementZindex(item);

    //se il testo non è stato digitato dall'utente E non proviene da una configurazione salvata o ereditata lo resetto
    if (!this.props.delegate.isSvgTextTypedByUser() && Object.keys(this.props.value).length === 0) {
      this.props.delegate.chosePreviewSvgText();
      if (item.rowCode) {
        this.props.delegate.setConfigurationTexts(this.name, item.rowCode, '');
      }
      await this.collectErrors(item, '', item.rowCode);
      this.propagateErrors();
    }
    this.props.delegate.setPreviewSvg();
  }
}
