import React from 'react';
import classNames from 'classnames';
import { GraphicCollapsableWidget } from '../../core/components/graphicCollapsableWidget';
import Logger from '../../core/logger';
import { AeImage } from '../../core/components/AeImage';
import { AeMarkupText } from '@photosi/albumepoca-ui';

export class PackagingUvGraphicElementWidget extends GraphicCollapsableWidget {
  itemClass(item) {
    return classNames('switchers-images__item', { active: item.svgName === this.props.selected });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items && this.props.selected !== nextProps.selected) {
      this.setState({ isChosen: false }); //need to reopen the item selector
      this.collectErrors(nextProps);
    }

    if (nextProps.items && this.props.items !== nextProps.items) {
      this.props.delegate.graphicsLoaded = false;
      if (nextProps.items.length === 0) {
        this.props.delegate.clearPreviewSvg();
      }
    }
  }

  get name() {
    return 'packagingUvGraphicElementType';
  }

  get label() {
    return this.i18n.gettext('elemento grafico');
  }

  handleMouseOver(item) {
    this.props.delegate.setPreviewSvg(item.svgUrl);
  }

  handleMouseOut() {
    this.props.delegate.setPreviewSvg();
  }

  handleClick(item) {
    super.handleClick(item);
    this.props.delegate.chosePreviewSvg(item.svgUrl);
    this.props.delegate.setPreviewSvg(item.svgUrl);
    this.props.delegate.chosePreviewSvgColor();
  }

  propagateClick() {
    if (this.clickedItem) {
      this.setState({ isChosen: true });
      this.props.delegate.setConfigurationItem(
        'packagingUvGraphicElementSvgName',
        this.clickedItem.svgName,
        null,
        false
      );
      this.props.delegate.setConfigurationItem(
        'packagingUvGraphicElementType',
        this.clickedItem.id,
        null,
        false
      );
    }
  }

  async uploadUvGraphics(svgUrl) {
    var request = new XMLHttpRequest();
    await request.open('GET', svgUrl, true);
    request.onerror = function (err) {
      Logger.error('uploadUvGraphics: Error on SVG file retrieve', {
        url: svgUrl,
        error: err
      });
    };
    request.send();
  }

  renderItems() {
    let renderedItems = [];
    if (this.props.items && this.props.items.length > 0) {
      renderedItems = this.props.items.map((item) => {
        //carico tutte le grafiche uv per cacharli
        if (!this.props.delegate.graphicsLoaded) {
          this.uploadUvGraphics(item.svgUrl);
        }
        return (
          <div
            key={item.id}
            className="col-xs-6 col-sm-4 col-md-6 padding-color"
            onMouseEnter={this.handleMouseOver.bind(this, item)}>
            <a onClick={this.handleClick.bind(this, item)} className={this.itemClass(item)}>
              <span className="switchers-images__item__image">
                <AeImage src={item.fileUrl} />
              </span>
            </a>
          </div>
        );
      });
    }
    if (renderedItems.length > 0) {
      this.props.delegate.graphicsLoaded = true;
    }
    return renderedItems;
  }

  /*
   * sovrascrivo il metodo per cmbiare la logica di questo widget
   * il widget si basa sul svgName invece di id - serve per identificare il gruppo di grafiche svg
   * */

  renderSelectedItem() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        if (item.svgName === this.props.selected) {
          return (
            <div key={item.id} className="col-xs-6 col-sm-4 col-md-6 padding-color vcenter">
              <a className={this.itemClass(item)}>
                <span className="switchers-images__item__image">
                  <AeImage src={item.fileUrl} />
                </span>
              </a>
            </div>
          );
        }
      });
    }
    return renderedItems;
  }

  validateSelectedNotAvailableInItemSet(props) {
    let isSelected = false;
    let selected = props.selected;
    let error = null;
    props.items.each(function (item, $selected, $isSelected) {  //eslint-disable-line
      isSelected = isSelected || item.svgName === selected;
    });

    if (!isSelected) {
      error = {
        message: this.i18n.gettext(
          'Le opzioni selezionate non portano ad una configurazione valida'
        ),
        code: null
      };
    }
    return error;
  }

  /*render the full length version of the widget*/
  renderFull() {
    return (
      <div className="product__formats">
        <div className="row">
          <div className="padding-color">
            <AeMarkupText text={this.textDescription} />
          </div>
        </div>
        <div className="row" onMouseLeave={this.handleMouseOut.bind(this)}>
          {this.renderItems()}
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}
