import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import { AlbumPackagingController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('album-packaging:main', AlbumPackagingController)
    };
  }
}
